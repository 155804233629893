





























































import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { IBoxInventoryTemplate } from '@/services/Repositories/boxInventoryTemplate/types';
import LocalStorage from '@/services/localStorage';

export default Vue.extend({
  name: 'ReboxDetails',
  data() {
    return {
      boxStatesLocal: [
        {
          id: 1,
          stateName: 'fine',
          description: this.$t('return.boxIsFine'),
          stateIsTrue: false,
        },
        {
          id: 2,
          stateName: 'damaged',
          description: this.$t('return.boxIsDamaged'),
          stateIsTrue: false,
        },
      ],
      selectedItems: [] as string[],
      selectItems: ['Damage to the box', 'Broken lid'],
      selectedTemplate: '',
    };
  },
  props: {
    input: {
      type: String,
    },
    reboxId: {
      type: String,
      default: () => '',
    },
    showCustomerDetails: {
      type: Boolean,
      default: () => false,
    },
    customerDetails: {
      type: Object,
    },
    templates: {
      type: Array as () => Array<IBoxInventoryTemplate>,
    },
    amountChanged: {
      type: Boolean,
    },
    templateId: {
      type: String,
    },
  },
  computed: {
    ...mapState('globals', ['loader', 'boxStates']),
    availableTemplates() {
      return this.templates.map((item: IBoxInventoryTemplate) => {
        const { id: value, name: text } = item;
        return {
          text,
          value,
        };
      });
    },
    icon(): string {
      if (this.allTemplateSelected) {
        return 'mdi-close-box';
      }
      return 'mdi-checkbox-blank-outline';
    },
    allTemplateSelected(): boolean {
      return this.selectItems.length === this.selectedItems.length;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_BOXSTATE_FINE', 'SET_BOXSTATE_DAMAGED']),
    selectAll(): void {
      if (this.allTemplateSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.selectItems.slice();
      }
    },
    onlyOneCheckBox(value: string): void {
      if (value === this.boxStatesLocal[0].stateName) {
        this.boxStatesLocal[1].stateIsTrue = false;
        this.boxStatesLocal[0].stateIsTrue = !this.boxStatesLocal[0].stateIsTrue;
        this.SET_BOXSTATE_FINE(this.boxStatesLocal[0].stateIsTrue);
        this.SET_BOXSTATE_DAMAGED(this.boxStatesLocal[1].stateIsTrue);
        return;
      }
      this.boxStatesLocal[0].stateIsTrue = false;
      this.boxStatesLocal[1].stateIsTrue = !this.boxStatesLocal[1].stateIsTrue;
      this.SET_BOXSTATE_FINE(this.boxStatesLocal[0].stateIsTrue);
      this.SET_BOXSTATE_DAMAGED(this.boxStatesLocal[1].stateIsTrue);
    },
  },
  watch: {
    selectedTemplate: {
      handler(value: number) {
        const template = this.templates.find(({ id }) => id === value);
        if (template) {
          const { boxInventoryTemplateItems } = template;
          this.$emit('getTemplateItems', boxInventoryTemplateItems);
          LocalStorage.setItem('savedTemplate', JSON.stringify(template.id));
        }
      },
    },
    templates: {
      handler(value: Array<IBoxInventoryTemplate>) {
        if (value.length) {
          this.selectedTemplate = this.templateId;
        }
      },
    },
    selectedItems: {
      handler(value: Array<string>) {
        if (value) {
          this.$emit('boxDamaged', value);
        }
      },
    },
    amountChanged: {
      handler(value: boolean) {
        if (value) {
          this.selectedTemplate = 'custom-template';
          this.$emit('changeObserver', false);
        }
      },
    },
  },
});
